/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.login {
  flex: 1;
  padding: 20px;
}
.login .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-form-button {
  width: 100%;
}
.home-content {
  width: 100%;
}
.home-top-section {
  margin-top: 20px;
  padding: 0 18px;
}
.home-top-section .home-top-section-left {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.home-top-section .panel {
  background-color: white;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
}
.home-top-section .panel .panel-header {
  background-color: #B4B4B4;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 25px;
}
.home-top-section .panel .panel-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 25px;
}
.home-top-section .panel .panel-body .description {
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  margin-bottom: 20px;
}
.home-top-section .panel .panel-body .items {
  flex: 1;
  margin-bottom: 10px;
  position: relative;
  padding: 0 15px;
}
.home-top-section .panel .panel-body .items .arrow {
  position: absolute;
  font-size: 2em;
  color: #B4B4B4;
  top: calc(50% - 14px);
}
.home-top-section .panel .panel-body .items .arrow.prev {
  left: -15px;
}
.home-top-section .panel .panel-body .items .arrow.next {
  right: -15px;
}
.home-top-section .panel .panel-body .items .ant-carousel,
.home-top-section .panel .panel-body .items .home-carousel,
.home-top-section .panel .panel-body .items .home-carousel .slick-list {
  height: 100%;
}
.home-top-section .panel .panel-body .items .home-carousel .slick-track,
.home-top-section .panel .panel-body .items .home-carousel .slick-list {
  display: flex;
}
.home-top-section .panel .panel-body .items .home-carousel .slick-slide {
  display: flex;
}
.home-top-section .panel .panel-body .items .home-carousel .slick-slide > div {
  flex: 1;
}
.home-top-section .panel .panel-body .items .home-carousel .slick-dots {
  margin: 0;
  bottom: -15px;
}
.home-top-section .panel .panel-body .items .home-carousel .slick-dots li button {
  background: #B4B4B4;
}
.home-top-section .panel .panel-body .items .home-carousel .slick-dots li button::before {
  display: none;
}
.home-top-section .panel.promotions .promo {
  min-height: 400px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.home-top-section .panel.forum .home-casousel-card.forum a {
  padding-left: 0;
}
.home-casousel-card {
  height: 100%;
  display: flex;
}
.home-casousel-card .title {
  border-bottom: 1px solid #e0e0e0;
  color: #707070;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}
.home-casousel-card .ant-card-body {
  padding: 15px;
  padding-top: 0;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.home-casousel-card .ant-card-body img {
  margin-bottom: 15px;
}
.home-casousel-card .ant-card-body .spacer {
  flex: 1;
}
.home-casousel-card .ant-card-body p {
  text-align: justify;
}
.home-casousel-card .ant-card-body button {
  padding-left: 0;
  text-transform: uppercase;
}
.home-divider {
  padding: 0 18px;
}
.home-divider .ant-divider-inner-text {
  color: #B4B4B4;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}
.masonry-wrapper.home {
  padding: 0 16px;
}
.library {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
@media screen and (max-width: 576px) {
  .library {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (min-width: 577px) and (max-width: 992px) {
  .library {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .library {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .library {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1601px) and (max-width: 9000px) {
  .library {
    grid-template-columns: repeat(3, 1fr);
  }
}
.library .home-card {
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.library .ant-card-body {
  padding: 15px 25px;
}
.library .custom-image {
  padding: 15px 0px;
  text-align: center;
}
.library .custom-image .icon {
  color: #B4B4B4;
  font-size: 80px;
}
.library .custom-card h3 {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  color: #707070;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 5px 0;
  text-align: center;
  text-transform: uppercase;
}
.library .custom-card .description {
  font-size: 14px;
  height: 80px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}
.home-banner-section {
  margin-top: 20px;
  padding: 0 18px;
  padding-bottom: 16px;
}
.home-banner-img {
  max-width: 100%;
  height: auto;
}
.news-details-wrapper header {
  height: auto;
}
.news-details-wrapper header .news-title {
  margin: 0;
}
.news-details-wrapper header .news-title span {
  margin-right: 10px;
}
.news-details-wrapper .latest-news-content {
  max-height: 350px;
  overflow: auto;
}
.news-details-wrapper .latest-news-content .news-detail-image {
  display: flex;
  margin: auto;
  width: auto;
  max-height: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .news-details-wrapper .latest-news-content .news-detail-image {
    max-width: 100%;
  }
}
.select-brand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
}
.select-brand .wrapper {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 420px) {
  .select-brand .wrapper {
    flex-direction: column;
  }
}
.select-brand .wrapper .box {
  cursor: pointer;
  margin: 20px;
  padding: 30px;
  background-color: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  transition: all ease-in-out 300ms;
}
.select-brand .wrapper .box:hover {
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.3);
}
.main-layout {
  background: #fff;
}
.grid-header {
  position: fixed;
  width: calc(100% - 250px);
  z-index: 90;
  display: flex;
  align-items: center;
}
.grid-header-mobile {
  width: 100%;
}
.popover-search-mobile {
  width: calc(100vw - 20px);
}
.popover-search-mobile .ant-popover-arrow {
  background: #B4B4B4 !important;
}
.popover-search-mobile .ant-popover-inner {
  background: #B4B4B4;
}
.popover-search-mobile .ant-popover-inner-content {
  padding: 8px;
}
.gallery {
  background: #efefef;
  min-height: 280px;
  margin-left: 250px;
}
.gallery-mobile {
  margin-left: 0;
}
.wrapper-library-breadcrumb {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.library-breadcrumb-item {
  cursor: pointer;
}
.library-list {
  padding: 65px 30px 0 30px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
@media screen and (max-width: 576px) {
  .library-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (min-width: 577px) and (max-width: 992px) {
  .library-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .library-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .library-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1601px) and (max-width: 9000px) {
  .library-list {
    grid-template-columns: repeat(5, 1fr);
  }
}
.library-list .thumb {
  border: 0px solid #ccc;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.library-list .ant-card-head {
  background: #B4B4B4;
  padding: 0 15px;
}
.library-list .ant-card-head .icon {
  font-size: 28px;
  vertical-align: middle;
  margin-right: 8px;
}
.library-list .ant-card-head .ant-card-head-title {
  color: #fff;
}
.library-list .ant-card-head .ant-card-head-title .card-title {
  display: flex;
  flex: 1;
}
.library-list .ant-card-head .ant-card-head-title .card-title p {
  line-height: 20px;
  font-size: 15px;
  flex: 1;
  word-break: break-word;
  white-space: normal;
  margin-bottom: 0;
}
.library-list .ant-card-body {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
}
.library-list .ant-card-body .ant-row:first-child {
  flex: 1;
}
.library-list .custom-image {
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.3);
  font-size: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.library-list .custom-image img {
  max-width: 100%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.library-list .custom-image .img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  opacity: 0;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.library-list .custom-image .img-overlay .icon {
  color: rgba(255, 255, 255, 0.8);
  font-size: 50px;
}
.library-list .custom-image:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.library-list .custom-image:hover .img-overlay {
  opacity: 1;
}
.library-list .custom-card {
  padding: 10px 15px;
}
.library-list .custom-card .details-bar,
.library-list .custom-card .typology-bar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
  margin-right: -5px;
  max-height: 730px;
  overflow: hidden;
  transition: all 250ms ease-in-out;
}
.library-list .custom-card .details-bar.clip,
.library-list .custom-card .typology-bar.clip {
  max-height: 53px;
}
.library-list .custom-card .typology-bar .anticon {
  margin-top: 5px;
  margin-right: 5px;
}
.library-list .custom-card h4 {
  margin-bottom: 5px;
  margin-right: 5px;
}
.library-list .custom-card .market-tag {
  margin: 0 4px 4px 0;
  border-color: #B4B4B4;
  flex: 0;
}
.library-list .custom-card .market-tag.show-more {
  flex: 0;
}
.library-list .custom-card .tag-wrapper .tag-content::before {
  content: "-";
  color: #B4B4B4;
  margin-right: 5px;
  text-decoration: none;
}
.library-list .custom-card .tag-wrapper .tag-content:first-child::before {
  content: "";
  margin-right: 0;
}
.library-list .custom-card .tag-wrapper .item-tag {
  background: transparent;
  border: 0;
  color: #B4B4B4;
  margin: 0 5px 5px 0;
  padding-left: 0;
  padding-right: 0;
  text-decoration: underline;
  font-style: italic;
}
.library-list .custom-card .tag-wrapper .item-tag.add-remove {
  background-color: #B4B4B4;
  border-color: #B4B4B4;
  color: white;
}
.library-list .custom-card .btns-wrapper {
  display: flex;
  align-items: center;
}
.library-list .custom-card .btns-wrapper .download-btn {
  flex: 1;
  margin-top: 10px;
  margin-right: 2px;
  text-transform: uppercase;
}
.library-list .custom-card .btns-wrapper .progress {
  flex: 1;
  margin-top: 10px;
  margin-right: 10px;
}
.library-list .custom-card .btns-wrapper .share-btn {
  flex: 0 1 auto;
  margin-top: 10px;
  margin-left: 2px;
}
.leaves.library-list {
  grid-auto-rows: auto;
}
.leaves .ant-card {
  display: flex;
}
.leaves .ant-card .ant-card-body {
  justify-content: flex-start;
  flex-grow: 1;
}
.leaves .ant-card .ant-card-body a:first-child {
  display: flex;
  flex: 1;
}
.leaves .ant-card .ant-card-body a:first-child .custom-image {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
}
.leaves .custom-card {
  display: flex;
  flex-direction: column;
}
.logoBrand {
  height: 45px;
  line-height: 45px;
}
@media screen and (max-width: 768px) {
  .logoBrand {
    height: 36px;
    line-height: 36px;
  }
}
.main-nav {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 100;
}
.main-nav > div {
  flex: 1;
}
.main-nav .brand {
  color: #7f7f7f;
  font-size: 22px;
}
.main-nav .brand span {
  color: black;
}
.main-nav .logo-link,
.main-nav .logo {
  height: 31px;
  line-height: 31px;
}
.main-nav .logoBrandApp {
  height: 50px;
  line-height: 50px;
}
@media screen and (max-width: 768px) {
  .main-nav .logoBrandApp {
    height: 36px;
    line-height: 36px;
  }
}
.menu-btn {
  font-size: 24px;
  height: 64px;
  border: 0 !important;
}
.menu.ant-menu-horizontal {
  line-height: 64px;
  border-bottom: none;
  background: transparent;
  display: inline-block;
}
.menu-mobile {
  border: 0;
}
.popover-menu-mobile {
  width: calc(100vw - 20px);
}
.popover-menu-mobile .ant-popover-inner-content {
  padding: 8px;
}
.logo-link {
  width: calc(250px - 25px);
  margin: 0 15px;
  border-right: 1px solid #707070;
}
.navbar {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navbar .menu {
  box-shadow: none;
}
.navbar .menu.link-menu {
  flex: 1;
}
.navbar .menu .ant-menu-item {
  position: relative;
  text-transform: uppercase;
}
.navbar .menu .ant-menu-item::after {
  position: absolute;
  content: " ";
  height: 20px;
  border-right: 1px solid #e0e0e0;
  right: -20px;
  top: 22px;
}
@media screen and (max-width: 768px) {
  .navbar .menu .ant-menu-item::after {
    content: none;
  }
}
.navbar .menu .ant-menu-item:nth-child(8)::after {
  content: none;
}
.welcome {
  margin: 0 10px 0 0;
}
@media screen and (max-width: 1100px) {
  .welcome {
    display: none;
  }
}
.social-icon {
  font-size: 18px;
  margin-right: 5px;
  vertical-align: middle;
}
.menu .flag-wrapper {
  text-align: center;
}
.flag {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  max-height: 16px;
  vertical-align: middle;
}
.flag.help {
  margin-right: 10px;
}
.flag-name {
  margin-left: 10px;
  vertical-align: middle;
}
.menu.ant-menu-horizontal > .ant-menu-item-active,
.menu.ant-menu-horizontal > .ant-menu-submenu-active,
.menu.ant-menu-horizontal > .ant-menu-item-selected,
.menu.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent;
  color: rgba(0, 0, 0, 0.65);
}
.masonry-wrapper {
  padding: 65px 30px 0 30px;
}
.masonry-wrapper .masonry {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px;
  /* gutter size offset */
  padding-top: 15px;
  width: auto;
}
.masonry-wrapper .masonry .masonry-column {
  padding-left: 15px;
  /* gutter size */
  background-clip: padding-box;
}
.masonry-wrapper .masonry .masonry-column .thumb {
  border: 0px solid #ccc;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
}
.masonry-wrapper .masonry .masonry-column .ant-card-head {
  background: #B4B4B4;
  padding: 0 15px;
}
.masonry-wrapper .masonry .masonry-column .ant-card-head .icon {
  font-size: 28px;
  vertical-align: middle;
  margin-right: 8px;
}
.masonry-wrapper .masonry .masonry-column .ant-card-head .ant-card-head-title {
  color: #fff;
}
.masonry-wrapper .masonry .masonry-column .ant-card-head .ant-card-head-title .card-title {
  display: flex;
  flex: 1;
}
.masonry-wrapper .masonry .masonry-column .ant-card-head .ant-card-head-title .card-title p {
  line-height: 20px;
  font-size: 15px;
  flex: 1;
  word-break: break-word;
  white-space: normal;
  margin-bottom: 0;
}
.masonry-wrapper .masonry .masonry-column .custom-image {
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.3);
  font-size: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.masonry-wrapper .masonry .masonry-column .custom-image img {
  max-width: 100%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.masonry-wrapper .masonry .masonry-column .custom-image .img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  opacity: 0;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.masonry-wrapper .masonry .masonry-column .custom-image .img-overlay .icon {
  color: rgba(255, 255, 255, 0.8);
  font-size: 50px;
}
.masonry-wrapper .masonry .masonry-column .custom-image:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.masonry-wrapper .masonry .masonry-column .custom-image:hover .img-overlay {
  opacity: 1;
}
.masonry-wrapper .masonry .masonry-column .custom-card {
  padding: 10px 15px;
}
.masonry-wrapper .masonry .masonry-column .custom-card .details-bar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
  margin-right: -5px;
  max-height: 730px;
  overflow: hidden;
  transition: all 250ms ease-in-out;
}
.masonry-wrapper .masonry .masonry-column .custom-card .details-bar.clip {
  max-height: 53px;
}
.masonry-wrapper .masonry .masonry-column .custom-card h4 {
  margin-bottom: 5px;
  margin-right: 5px;
}
.masonry-wrapper .masonry .masonry-column .custom-card .market-tag {
  margin: 0 4px 4px 0;
  border-color: #B4B4B4;
  flex: 0;
}
.masonry-wrapper .masonry .masonry-column .custom-card .market-tag.show-more {
  flex: 0;
}
.masonry-wrapper .masonry .masonry-column .custom-card .tag-wrapper .tag-content::before {
  content: "-";
  color: #B4B4B4;
  margin-right: 5px;
  text-decoration: none;
}
.masonry-wrapper .masonry .masonry-column .custom-card .tag-wrapper .tag-content:first-child::before {
  content: "";
  margin-right: 0;
}
.masonry-wrapper .masonry .masonry-column .custom-card .tag-wrapper .item-tag {
  background: transparent;
  border: 0;
  color: #B4B4B4;
  margin: 0 5px 5px 0;
  padding-left: 0;
  padding-right: 0;
  text-decoration: underline;
  font-style: italic;
}
.masonry-wrapper .masonry .masonry-column .custom-card .tag-wrapper .item-tag.add-remove {
  background-color: #B4B4B4;
  border-color: #B4B4B4;
  color: white;
}
.masonry-wrapper .masonry .masonry-column .custom-card .btns-wrapper {
  display: flex;
  align-items: center;
}
.masonry-wrapper .masonry .masonry-column .custom-card .btns-wrapper .download-btn {
  flex: 1;
  margin-top: 10px;
  margin-right: 2px;
  text-transform: uppercase;
}
.masonry-wrapper .masonry .masonry-column .custom-card .btns-wrapper .progress {
  flex: 1;
  margin-top: 10px;
  margin-right: 10px;
}
.masonry-wrapper .masonry .masonry-column .custom-card .btns-wrapper .share-btn {
  flex: 0 1 auto;
  margin-top: 10px;
  margin-left: 2px;
}
.unpublish {
  color: #B4B4B4;
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.3);
}
.unpublish:hover {
  color: #fff;
  background: #000;
}
.details {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.details .ant-layout-header {
  z-index: 90;
}
.details .slick-slider,
.details .thumb-image {
  height: 60px !important;
  width: 100%;
}
.details .back-btn,
.details .info-btn {
  font-size: 24px;
  height: 64px;
  border: 0;
}
.details .back-btn {
  background: #B4B4B4;
  border-radius: 0;
  margin-left: 10px;
  margin-right: 0px;
}
.details h2 .icon {
  font-size: 1.6em;
  margin-right: 10px;
  vertical-align: middle;
}
.details .info-btn {
  background: none;
  color: #fff;
}
.details .info-btn:hover {
  background: none;
}
.details .thumbs-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #707070;
}
.details .thumbs {
  flex: 1;
  overflow: hidden;
  margin: 0 5px;
}
.details .thumb-image {
  display: inline-block;
  border: 1px solid transparent;
  margin: 0 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.details .thumb-image.active {
  border-color: #B4B4B4;
}
.details .nav-bnt {
  flex: 0 1 auto;
  padding: 0 6px;
  border: 0;
}
.details .nav-bnt i {
  font-size: 30px;
}
.details .details-title {
  display: inline-block;
  flex: 1;
  margin: 0 0 0 5px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .details .details-title {
    font-size: 14px;
  }
}
.details .details-content {
  padding-top: 64px;
  align-self: stretch;
  background: #fff;
  overflow: auto;
}
.details .details-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 128px);
}
.details .details-wrapper h2 {
  text-align: center;
  padding: 15px;
}
.details .detail-image {
  flex: 1 1 auto;
  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.details .detail-video {
  flex: 1 1 auto;
}
.details .progress {
  max-width: 150px;
}
.details.news {
  background-color: #efefef;
  box-shadow: inset 0px 1px 9px rgba(0, 0, 0, 0.15);
  overflow: auto;
}
.details.news .news-details-wrapper {
  background-color: white;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.15);
  padding-bottom: 50px;
  margin: 30px 0;
}
.details.news .news-details-wrapper .ant-layout-header {
  position: sticky;
  top: 0;
}
.details.news .news-details-wrapper .content {
  padding: 0 25px;
}
.details.news .news-details-wrapper .content::after {
  content: "";
  display: table;
  clear: both;
}
.details.news .news-details-wrapper .content .title {
  color: #B4B4B4;
  font-size: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  margin-top: 30px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.details.news .news-details-wrapper .content .subtitle {
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  margin-bottom: 25px;
}
.details.news .news-details-wrapper .content .date {
  font-size: 1.2em;
  margin-bottom: 15px;
}
.details.news .news-details-wrapper .content .news-detail-image {
  width: 50%;
  float: right;
  margin-left: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .details.news .news-details-wrapper .content .news-detail-image {
    float: none;
    margin: 0;
    margin-bottom: 25px;
    width: 100%;
  }
}
.checkbox-wrapper,
.btn-wrapper {
  padding: 0 15px;
}
.btn-wrapper {
  margin-top: 5px;
}
.btn-copy {
  display: block;
  width: 100%;
  text-align: left;
}
.group-wrapper {
  background: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 15px 0;
  margin: 10px 0 20px 0;
}
.group-wrapper.links {
  background: #f0f0f0;
  border: none;
  border-radius: 5px;
}
.selection-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-drawer {
  top: 64px;
  bottom: 0;
  width: 0;
  height: auto;
  z-index: 9;
}
.ant-drawer.ant-drawer-left.ant-drawer-open {
  width: 250px;
}
.ant-drawer.ant-drawer-left.ant-drawer-open.mobile {
  top: 128px;
  width: 100%;
}
.ant-drawer-content {
  background: #e0e0e0;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.15);
}
.ant-drawer-body {
  padding: 0;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #B4B4B4;
  border-top-right-radius: 0;
}
.search-panel .ant-divider-inner-text {
  color: #B4B4B4;
  font-weight: bold;
  text-transform: uppercase;
}
.search-panel .ant-layout-sider-zero-width-trigger {
  /* top: 0; */
}
.search-panel .anticon-bars:before {
  content: "\E670";
}
.search-panel h3 {
  margin-bottom: 5px;
}
.search-panel .search-wrapper {
  padding: 15px;
}
.search-panel .search-panel-element {
  margin-bottom: 35px;
  width: 100%;
}
.search-panel .autocomplete-panel-element {
  margin-bottom: 0px;
  width: 100%;
}
.search-panel .autocomplete-panel-element button {
  display: none;
}
.search-panel .sk-folding-cube .sk-cube:before {
  background-color: #fff;
}
.search-panel .ant-switch-checked {
  background-color: #e0e0e0;
}
/*
* search resettable tags
*/
.ant-select-selection--multiple .ant-select-selection__choice {
  color: #B4B4B4;
  background-color: #e0e0e0;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #fff;
}
.toggle-select-all-children {
  display: flex;
  align-items: center;
}
.toggle-select-all-children .help-text {
  flex: 1;
  color: #fff;
}
.toggle-select-all-children .ant-btn-primary {
  margin-left: 5px;
  color: #e0e0e0;
  background-color: #fff;
  border-color: #fff;
}
.markets-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.market-tag {
  flex: 1;
  text-align: center;
}
.ant-tag-checkable.market-tag:active,
.ant-tag-checkable-checked.market-tag {
  color: #fff;
}
.typology-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
.ant-tag-checkable {
  background-color: #fff;
  height: auto;
  margin: 1%;
  padding: 4px;
  text-align: center;
}
.ant-tag-checkable .icon {
  color: #B4B4B4;
}
.ant-tag-checkable.ant-tag-checkable-checked {
  background-color: #B4B4B4;
}
.ant-tag-checkable.ant-tag-checkable-checked .icon {
  color: white;
}
.ant-tag-checkable.typology-tag .icon {
  font-size: 37px;
}
.save-tags-btn {
  width: 100%;
}
.search-panel-element {
  margin-bottom: 20px;
  width: 100%;
}
.main-layout.news-page .grid-header {
  background-color: #efefef;
  box-shadow: none;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 420px) {
  .main-layout.news-page .grid-header {
    height: auto;
  }
}
.main-layout.news-page .grid-header .header-row {
  flex: 1;
}
.main-layout.news-page .grid-header .header-row .news-filters {
  display: flex;
  align-items: center;
  padding: 0 18px;
}
.main-layout.news-page .grid-header .header-row .news-filters .founds {
  text-align: left;
}
.main-layout.news-page .grid-header .header-row .news-filters .founds span {
  background-color: white;
  border: 1px solid #d9d9d9;
  padding: 7px 15px;
  margin-right: 5px;
}
.main-layout.news-page .grid-header .header-row .news-filters .search-sort-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
}
.main-layout.news-page .grid-header .header-row .news-filters .search-sort-wrapper .search-news-wrapper {
  flex: 1;
  margin-right: 5px;
  display: inline-block;
  line-height: initial;
}
@media screen and (max-width: 420px) {
  .main-layout.news-page .grid-header .header-row .ant-col {
    width: 100%;
  }
  .main-layout.news-page .grid-header .header-row .ant-col .news-filters {
    flex-direction: column;
  }
  .main-layout.news-page .grid-header .header-row .ant-col .news-filters .founds {
    line-height: 18px;
    width: 100%;
  }
  .main-layout.news-page .grid-header .header-row .ant-col .news-filters .founds span {
    display: block;
    margin: 7px 0;
  }
  .main-layout.news-page .grid-header .header-row .ant-col .news-filters .search-sort-wrapper {
    margin-bottom: 8px;
    width: 100%;
  }
  .main-layout.news-page .grid-header .header-row .ant-col .news-filters .search-sort-wrapper .search-news-wrapper {
    width: 100%;
  }
}
.main-layout.news-page .gallery {
  margin-left: 0;
}
.main-layout.news-page .gallery .thumb.news .card-title {
  align-items: center;
}
.main-layout.news-page .gallery .thumb.news .card-title p {
  text-align: left;
}
.main-layout.news-page .gallery .thumb.news .details-bar {
  padding: 8px 0;
}
.main-layout.news-page .gallery .thumb.news .details-bar .title {
  margin-bottom: 5px;
  text-transform: uppercase;
}
.main-layout.news-page .gallery .thumb.news .news-btn {
  margin-top: 5px;
  text-transform: uppercase;
}
@media screen and (max-width: 420px) {
  .main-layout.news-page .masonry-wrapper {
    padding-top: 80px;
  }
}
body {
  color: #707070;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  overflow: auto !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #707070;
  font-weight: bold;
}
.ant-layout-header {
  background: #fff;
  box-shadow: 5px 1px 9px rgba(0, 0, 0, 0.15);
  padding: 0 10px;
}
.modal-open {
  overflow: hidden !important;
}
.btn-transparent {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
.btn-transparent:hover,
.btn-transparent:focus {
  color: #B4B4B4;
  background-color: #fff;
  border-color: #B4B4B4;
}
.btn-white {
  color: #B4B4B4;
}
.btn-white:hover,
.btn-white:focus {
  border-color: #B4B4B4;
}
.ant-message {
  z-index: 1000000;
}
.main-content {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  position: relative;
}
.ant-back-top {
  right: 18px;
  bottom: 18px;
  z-index: 100;
}
.ant-back-top-content {
  background-color: #B4B4B4;
}
.external-link {
  font-size: 14px;
  color: #999;
  margin-left: 5px;
  text-align: center;
}
/*******
loader
******/
.sk-folding-cube {
  margin: 40px auto;
  margin-bottom: 80px;
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #B4B4B4;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube2::before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube3::before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube4::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
/**********
video js
*********/
.vjs-big-play-centered .video-react-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -23px;
  margin-left: -45px;
}
/**********
solo per ie 11
*********/
@media all and (-ms-high-contrast: none) {
  .ant-layout-content .login,
  .login {
    display: block !important;
  }
  .ant-layout-content {
    /* height: calc(100vh - 64px); */
    flex: none;
    min-height: none;
  }
  .main-content {
    display: block;
    flex-direction: none;
  }
  .detail-image,
  .detail-video {
    flex: auto;
    height: calc(100vh - 128px);
  }
  .home-top-section .panel {
    display: block;
  }
  .home-top-section .panel.promotions {
    display: flex;
  }
  .home-top-section .panel.promotions .panel-body {
    display: flex;
  }
  .home-top-section .panel.promotions .panel-body .items {
    height: auto;
  }
  .home-top-section .panel .panel-body {
    display: block;
    flex: auto;
  }
  .home-top-section .panel .panel-body .items {
    height: 100%;
  }
  .home-top-section .panel .panel-body .items .home-carousel .slick-track,
  .home-top-section .panel .panel-body .items .home-carousel .slick-list {
    display: block;
    height: 100%;
  }
  .home-top-section .panel .panel-body .items .home-carousel .slick-slide {
    display: block;
  }
  .home-top-section .panel .panel-body .items .home-carousel .slick-slide > div {
    height: 100%;
  }
  .home-top-section .panel .panel-body .items .home-carousel .slick-dots {
    margin: 0;
    bottom: -15px;
  }
  .home-top-section .panel .panel-body .items .home-carousel .slick-dots li button {
    background: #B4B4B4;
  }
  .home-top-section .panel .panel-body .items .home-carousel .slick-dots li button::before {
    display: none;
  }
  .home-casousel-card .ant-card-body {
    display: block;
  }
  .ant-tag-checkable.typology-tag {
    width: 31.333%;
  }
}
.details.news {
  background-color: white;
}
.ant-btn {
  text-transform: uppercase;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.ant-divider-inner-text {
  padding-left: 0 !important;
}
.ant-divider-horizontal.ant-divider-with-text-left.home-divider::before,
.ant-divider-horizontal.ant-divider-with-text-left.filters-label::before {
  width: 0;
}
.nha .news-details-wrapper,
.nha .news-details-wrapper .ant-layout-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nha .home-top-section .panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nha .home-top-section .panel .panel-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nha .ant-divider-horizontal.ant-divider-with-text-left.home-divider::before,
.nha .ant-divider-horizontal.ant-divider-with-text-left.filters-label::before,
.nha .ant-divider-horizontal.ant-divider-with-text-left.home-divider::after,
.nha .ant-divider-horizontal.ant-divider-with-text-left.filters-label::after {
  border-top: 1px solid #333333;
}
.nha .masonry-wrapper .masonry .masonry-column .thumb {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nha .masonry-wrapper .masonry .masonry-column .thumb .ant-card-head {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nha .details .ant-layout-header {
  background: #00377d;
}
.nha .details .ant-layout-header .back-btn {
  color: white;
}
.caseih .news-details-wrapper,
.caseih .news-details-wrapper .ant-layout-header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.caseih .home-top-section .panel {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.caseih .home-top-section .panel .panel-header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.caseih .ant-divider-horizontal.ant-divider-with-text-left.home-divider::before,
.caseih .ant-divider-horizontal.ant-divider-with-text-left.filters-label::before,
.caseih .ant-divider-horizontal.ant-divider-with-text-left.home-divider::after,
.caseih .ant-divider-horizontal.ant-divider-with-text-left.filters-label::after {
  border-top: 1px solid #707070;
}
.caseih .masonry-wrapper .masonry .masonry-column .thumb {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.caseih .masonry-wrapper .masonry .masonry-column .thumb .ant-card-head {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.details .ant-layout-header {
  background: #b12430;
}
.details .ant-layout-header .back-btn {
  color: white;
}
